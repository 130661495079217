var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"antd-layout-container"},[_c('div',{staticClass:"login-panel"},[_c('div',[_c('h2',{staticStyle:{"font-size":"28px"}},[_vm._v("智慧水务管理系统")]),_c('h2',[_vm._v("内蒙古汇能集团尔林兔煤炭有限公司")]),_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.login}},[_c('p',{staticClass:"des"},[_vm._v("控制台登录")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'userName',
              { rules: [{ required: true, message: '请输入账号!' }] } ]),expression:"[\n              'userName',\n              { rules: [{ required: true, message: '请输入账号!' }] },\n            ]"}],attrs:{"size":"large","placeholder":"账号/邮箱"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              { rules: [{ required: true, message: '请输入密码!' }] } ]),expression:"[\n              'password',\n              { rules: [{ required: true, message: '请输入密码!' }] },\n            ]"}],attrs:{"size":"large","type":"password","placeholder":"密码","autocomplete":""}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'remember',
              {
                valuePropName: 'checked',
                initialValue: true,
              } ]),expression:"[\n              'remember',\n              {\n                valuePropName: 'checked',\n                initialValue: true,\n              },\n            ]"}]},[_vm._v("记住我")]),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit","size":"large","loading":_vm.loading}},[_vm._v("登录")]),_c('a',{staticClass:"login-form-forgot",attrs:{"href":""}},[_vm._v("忘记密码？")]),_c('a',{attrs:{"href":""}},[_vm._v("现在注册!")])],1)],1)],1)]),_c('div',{staticClass:"bg"}),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"copyright"},[_c('p',[_vm._v("请使用IE9.0以上或Chrome(谷歌)浏览器访问本系统")]),_c('p',[_vm._v("Copyright ©2019 OnePower Corporation, All Rights Reserved")]),_c('p',[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("豫ICP备2021025725-1号")])])])}]

export { render, staticRenderFns }